import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


const KeyDates = ({ className }) => {
  const { melbourne, sydney, melCalIcon, sydCalIcon, postponed } = useStaticQuery(
    graphql`
      query {
        postponed: file(relativePath: { eq: "postponed.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        melbourne: file(relativePath: { eq: "kendra-vant-stp-8193.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sydney: file(relativePath: { eq: "stp-9673.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        melCalIcon: file(relativePath: { eq: "melbourne-calendar-icon.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sydCalIcon: file(relativePath: { eq: "sydney-calendar-icon.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  // Single Image Data
  const melIcon = melCalIcon.childImageSharp.fluid
  const melHero = melbourne.childImageSharp.fluid
  const sydIcon = sydCalIcon.childImageSharp.fluid
  const sydHero = sydney.childImageSharp.fluid
  const postponedImg = postponed.childImageSharp.fluid

  return (
    <div id={'KEY_DATES'}
         className={className + ' has-text-centered has-background-grey-lighter has-padding-20-mobile'}>
      <div className={'columns has-padding-top-50'}>
        <div className={'column'}>
          <h3
            className={'title is-size-1-tablet is-size-2-mobile is-uppercase has-text-weight-bold has-text-dark'}>KEY
            DATES</h3>
          <span className={'subtitle is-size-3-tablet is-size-4-mobile has-text-dark'}>MELBOURNE</span>{' '}
          <span className={'subtitle is-size-3 is-size-4-mobile has-text-dark has-padding-left-30'}>SYDNEY</span>
        </div>
      </div>
      <div className={'columns has-padding-top-20'}>
        <div className={'column is-offset-1 is-6-desktop is-5-tablet is-hidden-mobile is-vcentered'}>
          <div className={'card'}>
            <Img fluid={melHero} className={'has-image-centered'}/>
          </div>
        </div>
        <div className={'column is-12-mobile is-4-desktop is-5-tablet'}>
          <div className={'card'} style={{ height: '100%' }}>
            <div className="card-content">
              <Img fluid={melIcon} className={'has-image-centered has-margin-bottom-20'} style={{ maxWidth: '60px' }}/>
{/*              <h3 className={'title is-2-tablet is-4-mobile'}>MELBOURNE</h3>
              <h4 className={'is-size-4 has-text-weight-semibold'}>Masterclass</h4>
              <p>Monday, February 14, 2022</p>
              <p>Telstra Customer Insight Centre</p>
              <p>Level 1, 242 Exhibition Street, Melbourne VIC 3000</p>
              <strong>Registration from 8.30am</strong>
              <hr/>*/}
              <h4 className={'is-size-4 has-text-weight-semibold'}>Conference</h4>
              <p>Tuesday, February 15, 2022</p>
              <p>Grand Hyatt Melbourne</p>
              <p>123 Collins Street, Melbourne, VIC 3000</p>
              <strong>Registration from 8.15am</strong>
            </div>
            <div className={'card-footer has-margin-left-20 has-margin-right-20 has-padding-bottom-10'}>
              {/*<p className={'card-footer-item'}>*/}
              {/*  <Link to={'/tickets'} className="button is-link is-outlined is-small is-fullwidth is-uppercase">*/}
              {/*    <span>Get Your Tickets</span>*/}
              {/*  </Link>*/}
              {/*</p>*/}
              <p className={'card-footer-item'}>
                <Link to={'/speakers'}
                   className="button is-outlined is-small is-link is-fullwidth is-uppercase">
                  <span>See Our Speakers</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={'columns has-padding-top-50 has-padding-bottom-50'}>
        <div className={'column is-offset-1 is-4-desktop is-5-tablet'}>
          <div className={'card'} style={{ height: '100%' }}>
            <div className="card-content">
              <Img fluid={sydIcon} className={'has-image-centered has-margin-bottom-20'} style={{ maxWidth: '60px' }}/>
              <h3 className={'title is-2-tablet is-4-mobile'}>SYDNEY</h3>
              <h4 className={'is-size-4 has-text-weight-semibold'}>Conference</h4>
              <p>Thursday, February 17, 2022</p>
              <p>Doltone House - Jones Bay Wharf</p>
              <p>Level 3/26-32 Pirrama Road, Pyrmont, NSW 2009</p>
              <strong>Registration from 8.15am</strong>
{/*              <hr/>
              <h4 className={'is-size-4 has-text-weight-semibold'}>Masterclass</h4>
              <p>Friday, February 18, 2022</p>
              <p>Telstra Customer Insight Centre</p>
              <p>Level 2, 400 George Street, Sydney NSW 2000</p>
              <strong>Registration from 8.30am</strong>*/}
            </div>
            {/*<div className={'card-content is-overlay is-vcentered has-max-height-250'}>*/}
            {/*  <Img fluid={postponedImg} className={'has-image-centered has-margin-bottom-20'} style={{ maxWidth: '600px' }}/>*/}
            {/*</div>*/}
            <div className={'card-footer has-margin-left-20 has-margin-right-20 has-padding-bottom-10'}>
              {/*<p className={'card-footer-item'}>*/}
              {/*  <Link to={'/tickets'} className="button is-link is-outlined is-small is-fullwidth is-uppercase">*/}
              {/*    <span>Get Your Tickets</span>*/}
              {/*  </Link>*/}
              {/*</p>*/}
              <p className={'card-footer-item'}>
                <Link to={'/speakers'}
                      className="button is-outlined is-small is-link is-fullwidth is-uppercase">
                  <span>See Our Speakers</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className={'column is-6-desktop is-5-tablet is-hidden-mobile is-vcentered'}>
          <div className={'card'}>
            <Img fluid={sydHero} className={'has-image-centered'}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KeyDates
