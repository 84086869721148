import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


const Sponsors = ({ className }) => {
  const {
    ippon,
    badook,
    rea,
    xero,
    iag,
    auth0,
    digio,
    suse,
    hipages,
    messagemedia,
    twilio,
    tyro,
    myob,
    deloitte,
    macq,
    doit,
    coles,
    catchOf,
    tabcorp,
    indigitek,
  } = useStaticQuery(
    graphql`
      query {
        ippon: file(relativePath: { eq: "sponsors/ippon_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        badook: file(relativePath: { eq: "sponsors/badook_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rea: file(relativePath: { eq: "sponsors/rea_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        xero: file(relativePath: { eq: "sponsors/xero_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        deloitte: file(relativePath: { eq: "sponsors/deloitte_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iag: file(relativePath: { eq: "sponsors/iag_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        auth0: file(relativePath: { eq: "sponsors/auth0_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        digio: file(relativePath: { eq: "sponsors/digio_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        suse: file(relativePath: { eq: "sponsors/suse_logo.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hipages: file(relativePath: { eq: "sponsors/hipages_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        messagemedia: file(relativePath: { eq: "sponsors/messagemedia_logo.webp" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        twilio: file(relativePath: { eq: "sponsors/twilio_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        myob: file(relativePath: { eq: "sponsors/myob_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tyro: file(relativePath: { eq: "sponsors/tyro_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        macq: file(relativePath: { eq: "sponsors/macq_logo.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        catchOf: file(relativePath: { eq: "sponsors/catch_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        coles: file(relativePath: { eq: "sponsors/coles_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        doit: file(relativePath: { eq: "sponsors/doit_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tabcorp: file(relativePath: { eq: "sponsors/tabcorp_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
        indigitek: file(relativePath: { eq: "sponsors/indigitek_logo.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const ipponImg = ippon.childImageSharp.fluid
  const badookImg = badook.childImageSharp.fluid
  const reaImg = rea.childImageSharp.fluid
  const xeroImg = xero.childImageSharp.fluid
  const iagImg = iag.childImageSharp.fluid
  const auth0Img = auth0.childImageSharp.fluid
  const digioImg = digio.childImageSharp.fluid
  const suseImg = suse.childImageSharp.fluid
  const hipagesImg = hipages.childImageSharp.fluid
  const twilioImg = twilio.childImageSharp.fluid
  const tyroImg = tyro.childImageSharp.fluid
  const myobImg = myob.childImageSharp.fluid
  const messagemediaImg = messagemedia.childImageSharp.fluid
  const deloitteImg = deloitte.childImageSharp.fluid
  const macqImg = macq.childImageSharp.fluid
  const colesImg = coles.childImageSharp.fluid
  const catchImg = catchOf.childImageSharp.fluid
  const doitImg = doit.childImageSharp.fluid
  const tabcorpImg = tabcorp.childImageSharp.fluid
  const indigitekImg = indigitek.childImageSharp.fluid

  return (
    <div className={className + ' has-padding-30'}>
      <h4 className={'title is-2'}>OUR SPONSORS</h4>
      <h5 className={'subtitle is-3 has-padding-top-30'}>PLATINUM</h5>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://messagemedia.com/au/">
            <Img fluid={messagemediaImg} className={'has-image-centered'} alt="MessageMedia"
                 style={{
                   maxWidth: '400px',
                 }}/>
          </a>
        </div>
      </div>

      <h5 className={'subtitle is-3 has-padding-top-30'}>GOLD</h5>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://www.realestate.com.au">
            <Img fluid={reaImg} className={'has-image-centered'} alt="REA - Real Estate Australia"
                 style={{
                   maxWidth: '350px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.suse.com/">
            <Img fluid={suseImg} className={'has-image-centered'}
                 alt="SUSE - Open Source Solutions for Enterprise Servers & Cloud"
                 style={{
                   maxWidth: '350px',
                 }}/>
          </a>
        </div>
      </div>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://www.twilio.com">
            <Img fluid={twilioImg} className={'has-image-centered has-margin-top-30'} alt="Twilio"
                 style={{
                   maxWidth: '300px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.hipages.com.au/">
            <Img fluid={hipagesImg} className={'has-image-centered'} alt="HiPages"
                 style={{
                   maxWidth: '300px',
                 }}/>
          </a>
        </div>
      </div>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://www.tyro.com/">
            <Img fluid={tyroImg} className={'has-image-centered'}
                 alt="Tyro Payments"
                 style={{
                   maxWidth: '280px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.myob.com/au">
            <Img fluid={myobImg} className={'has-image-centered'} alt="MYOB"
                 style={{
                   maxWidth: '280px',
                 }}/>
          </a>
        </div>
      </div>

      <h5 className={'subtitle is-3 has-padding-top-30'}>SILVER</h5>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://www.xero.com">
            <Img fluid={xeroImg} className={'has-image-centered'} alt="Xero"
                 style={{
                   maxWidth: '100px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.iag.com.au/">
            <Img fluid={iagImg} className={'has-image-centered'} alt="IAG"
                 style={{
                   maxWidth: '100px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://medium.com/macquarie-engineering-blog">
            <Img fluid={macqImg} className={'has-image-centered has-margin-top-20'} alt="Macquarie Bank"
                 style={{
                   maxWidth: '100px',
                 }}/>
          </a>
        </div>
      </div>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://auth0.com/developers">
            <Img fluid={auth0Img} className={'has-image-centered has-margin-top-20'} alt="Auth0"
                 style={{
                   maxWidth: '180px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www2.deloitte.com/au/en/pages/about-deloitte/articles/home.html">
            <Img fluid={deloitteImg} className={'has-image-centered has-margin-top-20'} alt="Deloitte"
                 style={{
                   maxWidth: '180px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.digio.com.au/">
            <Img fluid={digioImg} className={'has-image-centered has-margin-top-20'} alt="Digio"
                 style={{
                   maxWidth: '140px',
                 }}/>
          </a>
        </div>
      </div>
      <div className={'columns is-flex is-size-7-mobile'}>
        {/*<div className={'column'}>*/}
        {/*  <a href="https://www.doit-intl.com">*/}
        {/*    <Img fluid={doitImg} className={'has-image-centered'} alt="DoIT International"*/}
        {/*         style={{*/}
        {/*           maxWidth: '120px',*/}
        {/*         }}/>*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className={'column'}>
          <a href="https://www.catch.com.au">
            <Img fluid={catchImg} className={'has-image-centered has-margin-top-5'} alt="Catch of the Day"
                 style={{
                   maxWidth: '180px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.colesgroup.com.au/">
            <Img fluid={colesImg} className={'has-image-centered has-margin-top-30'} alt="Coles Group Australia"
                 style={{
                   maxWidth: '220px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://www.tabcorp.com.au">
            <Img fluid={tabcorpImg} className={'has-image-centered has-margin-top-20'} alt="Tabcorp"
                 style={{
                   maxWidth: '220px',
                 }}/>
          </a>
        </div>
      </div>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://ippon.tech">
            <Img fluid={ipponImg} className={'has-image-centered'} alt="Ippon Tech Australia"
                 style={{
                   maxWidth: '220px',
                 }}/>
          </a>
        </div>
        <div className={'column'}>
          <a href="https://badook.ai/">
            <Img fluid={badookImg} className={'has-image-centered has-margin-top-20'} alt="Badook AI"
                 style={{
                   maxWidth: '280px',
                 }}/>
          </a>
        </div>
      </div>
      <h5 className={'subtitle is-3 has-padding-top-30'}>Community Partners</h5>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <a href="https://www.indigitek.org.au/">
            <Img fluid={indigitekImg} className={'has-image-centered'} alt="Indigitek"
                 style={{
                   maxWidth: '260px',
                 }}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Sponsors
