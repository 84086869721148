import React from 'react'
import { graphql, useStaticQuery, withPrefix } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Helmet } from 'react-helmet/es/Helmet'

const Contact = ({ className }) => {
  const { programBg } = useStaticQuery(
    graphql`
      query {
        programBg: file(relativePath: { eq: "group-8.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const background = programBg.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={background}
      className={className}
      title="Contact Us"
      id="CONTACT"
      role="img"
      aria-label="contact us"
      preserveStackingContext={true}
    >
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer/>
      </Helmet>
      <div
        className={'column is-offset-2 is-4 has-background-light has-padding-bottom-40 has-padding-left-30 has-padding-right-30'}>
        <p className={'title is-3 has-padding-top-40 has-padding-bottom-10'}>CONTACT US</p>

        <form autoComplete={'on'} method={'POST'} action={'#'}>
          <div className="field">
            <div className="control">
              <label className="label is-small" htmlFor="first_name">First Name</label>
              <input id="first_name" className="input" type="text" placeholder="First Name" required/>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label is-small" htmlFor="last_name">Last Name</label>
              <input id="last_name" className="input" type="text" placeholder="Last Name" required/>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label is-small" htmlFor="email">Email</label>
              <input id="email" className="input" type="email" placeholder="Enter your email address" required/>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label is-small" htmlFor="message">Message</label>
              <textarea id="message" className="textarea" rows={6} placeholder="Enter your message" required/>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button id="submit" type={'submit'} className="button is-outlined is-link is-medium is-fullwidth">Send
              </button>
            </div>
          </div>

          <div id='captcha' className="g-recaptcha"
               data-badge="inline"
               data-sitekey="6Lc4LHYUAAAAAIXabhSktceWYVer_uEwm60Uyd4E"
               data-callback="onSubmitCaptcha"
               data-size="invisible">
          </div>
        </form>
      </div>
    </BackgroundImage>
  )
}

export default Contact
