import React from 'react'
import Slider from 'react-slick'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Speakers = ({ className }) => {

  const { anna, charles, derek, john, milly, ronen } = useStaticQuery(
    graphql`
      query {
        anna: file(relativePath: { eq: "speakers/anna.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        charles: file(relativePath: { eq: "speakers/charles.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        derek: file(relativePath: { eq: "speakers/derek.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        john: file(relativePath: { eq: "speakers/john.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        milly: file(relativePath: { eq: "speakers/milly.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ronen: file(relativePath: { eq: "speakers/ronen.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 2,
    swipeToSlide: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const features = [
    {
      title: 'What’s all this about Quantum?',
      speaker: 'John Azariah',
      thumb: john.childImageSharp.fluid,
    },
    {
      title: 'Dev/Ops relationships, status: It\'s Complicated',
      speaker: 'Anna Fiofilova',
      thumb: anna.childImageSharp.fluid,
    },
    {
      title: 'Shipping a Time Machine',
      speaker: 'Derek Troy-West',
      thumb: derek.childImageSharp.fluid,
    },
    {
      title: 'Five lessons I’ve learnt building an open source developer tool',
      speaker: 'Charles Korn',
      thumb: charles.childImageSharp.fluid,
    },
    {
      title: 'Learn you some security for the great good!',
      speaker: 'Ronen Narkis',
      thumb: ronen.childImageSharp.fluid,
    },
    {
      title: 'Arrow to the heart: A year of functional programming in our Kotlin codebase',
      speaker: 'Milly Rowett',
      thumb: milly.childImageSharp.fluid,
    },
  ]

  return (
    <div className={className}>
      <div className={'columns'}>
        <div className={'column'}>
          <h3 className={'title is-2-tablet is-3-mobile is-uppercase has-text-weight-bold'}>Speakers</h3>
          <p>
            <Link to={'/speakers'}>View all of our speakers this year</Link>
          </p>
        </div>
      </div>
      <Slider {...settings} className={'is-size-7-mobile is-size-6-tablet'}>
        {features.map((v) => {
          return (<div key={v.title}>
            <div className="thumb">
              <Link to={'/speakers'}>
                <Img fluid={v.thumb}
                     alt={`${v.title} - ${v.speaker}`}/>
              </Link>
            </div>
            <div className="desc">
            </div>
          </div>)
        })}
      </Slider>
    </div>
  )
}

export default Speakers
