import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'


const Program = ({ className }) => {
  const { programBg } = useStaticQuery(
    graphql`
      query {
        programBg: file(relativePath: { eq: "group-6.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  // Single Image Data
  const background = programBg.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={background}
      id="PROGRAM"
      role="img"
      preserveStackingContext={true}
    >
      <div className={className + ' has-padding-30-mobile'}>
        {/*<div className={'hero-body'}>*/}
        <div className={'columns'}>
          <div className={'column is-offset-1-desktop is-5-desktop'}>
            <div className={'is-vcentered'}>
              <div className={'card has-padding-top-100-tablet has-padding-bottom-100-tablet'}>
                <div className="card-content">
                  <h3 className={'title is-2'}>THE PROGRAM</h3>
                  <p className={'subtitle is-4 has-padding-top-20 has-padding-bottom-20'}>Experience insightful conference sessions and in-depth masterclasses across cutting edge tracks.</p>
                  <div className={'columns'}>
                    <div className={'column is-6'}>
                      <div className={'card'}>
                        <header className="card-header has-background-primary-dark">
                          <p className="card-header-title has-text-white">
                            MASTERCLASSES
                          </p>
                        </header>
                        <div className="card-content has-background-white-ter">
                          <div className="content">
                            <small>Deep dive into engaging tracks with targeted, technical classes taught by experts in the
                              field.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'column is-6'}>
                      <div className={'card'}>
                        <header className="card-header has-background-link-dark">
                          <p className="card-header-title has-text-white">
                            CONFERENCE
                          </p>
                        </header>
                        <div className="card-content has-background-white-ter">
                          <div className="content">
                            <small>Mix and match sessions across a breadth of topics delivered by leaders in the
                              industry.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>You ticket includes complimentary culinary delights and networking opportunities with industry
                    leaders.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Program
