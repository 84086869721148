import React from 'react'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import About from '../components/About'
import KeyDates from '../components/KeyDates'
import Program from '../components/Program'
import Contact from '../components/Contact'
import Sponsors from '../components/Sponsors'
import Team from '../components/Team'
import Tracks from '../components/Tracks'
import CallForPaper from '../components/CallForPaper'
import LastYear from '../components/LastYear'
import { useSiteMetadata } from '../hooks'
import Speakers from '../components/Speakers'

const Index = () => {
  const { title, description } = useSiteMetadata()

  return (
    <Layout title={title} description={description}>

      <Hero className={'hero is-transparent is-fullheight'}/>

      <About className={'hero is-large'}/>

      <KeyDates className={'hero is-large'}/>

      <Speakers className={'has-background-white has-padding-top-50 has-padding-bottom-80 has-text-centered'}/>

      <Program className={'hero is-large'}/>

      <LastYear className={'has-background-white has-padding-top-50 has-padding-bottom-80 has-text-centered'}/>

      {/*<CallForPaper className={'has-background-light has-padding-top-50 has-padding-bottom-80'}/>*/}

      <Tracks className={'has-background-light has-padding-top-50 has-padding-bottom-30'}/>

      <Team className={'has-background-light has-padding-top-50 has-padding-bottom-80 has-text-centered'}/>

      <Sponsors className={'has-padding-top-50 has-padding-bottom-80 has-text-centered'}/>

      <Contact className={'hero is-large'}/>

    </Layout>
  )
}

export default Index
