import React from 'react'
import Slider from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const LastYear = ({ className }) => {

  const { andrew, rosenbaum, scott, cam, wei, vitess, agustinus, eugene, adi } = useStaticQuery(
    graphql`
      query {
        andrew: file(relativePath: { eq: "youtube/andrew-jones.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rosenbaum: file(relativePath: { eq: "youtube/j-rosenbaum.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         scott: file(relativePath: { eq: "youtube/scott-coulton.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cam: file(relativePath: { eq: "youtube/cam-jackson.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wei: file(relativePath: { eq: "youtube/wei-yau.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        vitess: file(relativePath: { eq: "youtube/vitess-jon.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        agustinus: file(relativePath: { eq: "youtube/agustinus.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        eugene: file(relativePath: { eq: "youtube/eugene.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        adi: file(relativePath: { eq: "youtube/adi-polak.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const videos = [
    {
      videoId: 'dIbjar7mnhY',
      title: 'Demystifying Kotlin Coroutines',
      speaker: 'Adi Polak',
      thumb: adi.childImageSharp.fluid,
    },
    {
      videoId: 'TK4C7YadRo0',
      title: 'Expressive APIs with Kotlin',
      speaker: 'Eugene Petrenko',
      thumb: eugene.childImageSharp.fluid,
    },
    {
      videoId: 'muwUgjYGa7E',
      title: 'Kubernetes Security 101',
      speaker: 'Scott Coulton',
      thumb: scott.childImageSharp.fluid,
    },
    {
      videoId: 'dKs3xKNNg4Q',
      title: 'Our AI Journey towards Singularity',
      speaker: 'Agustinus Nalwan',
      thumb: agustinus.childImageSharp.fluid,
    },
    {
      videoId: 'lckl8Qb4SKg',
      title: 'AI and AR - A Match made in Heaven',
      speaker: 'J RosenBaum',
      thumb: rosenbaum.childImageSharp.fluid,
    },
    {
      videoId: 'u_tNgt1GYWE',
      title: 'Migrating from Monolith to Modern Architectures',
      speaker: 'Andrew Jones',
      thumb: andrew.childImageSharp.fluid,
    },
    {
      videoId: 'SkCYY-6LHYU',
      title: 'Scaling Square Cash',
      speaker: 'Vitess Karel A Sague & Jon Tirsen',
      thumb: vitess.childImageSharp.fluid,
    },
    {
      videoId: 'FeCwGCznqkY',
      title: 'Scaling ML model building with AWS Batch',
      speaker: 'Wai Yau & Derrick Cheng',
      thumb: wei.childImageSharp.fluid,
    },
    {
      videoId: 'C9QAQGy3V5Q',
      title: 'Evolving and scaling webapps using micro frontends',
      speaker: 'Cam Jackson',
      thumb: cam.childImageSharp.fluid,
    },
  ]

  return (
    <div className={className}>
      <div className={'columns'}>
        <div className={'column'}>
          <h3 className={'title is-2-tablet is-3-mobile is-uppercase has-text-weight-bold'}>See sessions from 2019 event</h3>
        </div>
      </div>
      <Slider {...settings} className={'is-size-7-mobile is-size-6-tablet'}>
        {videos.map((v) => {
          return (<div key={v.videoId}>
            <div className="thumb">
              <a href={`https://www.youtube.com/watch?v=${v.videoId}`} target={'_blank'}>
                <Img fluid={v.thumb}
                     alt={`${v.title} - ${v.speaker}`}/>
              </a>
            </div>
            <div className="desc">
              <strong>{v.title}</strong> <br/>
              <small>{v.speaker}</small>
            </div>
          </div>)
        })}
      </Slider>
    </div>
  )
}

export default LastYear
