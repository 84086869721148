import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'


const About = ({ className }) => {
  const { about, arun, mai } = useStaticQuery(
    graphql`
      query {
        about: file(relativePath: { eq: "about-voxxed-days-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        arun: file(relativePath: { eq: "talent-arun-hariharan-sivasubramaniyan.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 260) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mai: file(relativePath: { eq: "talent-mai-nguyen.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 260) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const aboutBg = about.childImageSharp.fluid
  const arunImg = arun.childImageSharp.fluid
  const maiImg = mai.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={aboutBg}
      title="About VoxxedDays Australia"
      id="ABOUT"
      role="img"
      aria-label="About VoxxedDays Australia"
      preserveStackingContext={true}
    >
      <div className={'has-text-centered has-padding-20-mobile'}>
        <div className={'columns has-padding-top-50'}>
          <div className={'column is-one-fifths-desktop is-hidden-mobile is-vbottom'}>
            <Img fluid={arunImg} className={'has-image-centered'} style={{
              maxWidth: '260px',
              objectFit: 'contain',
            }}/>
          </div>
          <div className={'column is-three-fifths has-text-white has-padding-bottom-80'}>
            <h3
              className={'title is-size-1-tablet is-size-3-mobile is-uppercase has-text-weight-bold has-padding-bottom-30 has-text-white'}>
              About Voxxed Days
            </h3>
            <p className={'subtitle is-size-3 is-size-4-mobile has-text-white'}>From Developers, For Developers</p>
            <div className={'content is-size-7-mobile'}>
              <p>Voxxed Days is the community developer conference series. Originating in Europe, Voxxed Days events now run in cities across the globe including Paris, Athens, Luxembourg, Belgrade, Banff and Singapore.
              </p>
              <p>
                The series first came to Australia in 2018 with an event in Melbourne that was followed in 2019 with events in both Melbourne and Sydney. Unfortunately the plans for 2020 and 2021 had to be shelved however that’s given us even more time to ensure that the 2022 event is one to behold!
              </p>
              <p>
                Our fundamentals have not changed and we’ll continue to deliver engaging content crafted for developers. Whilst international speakers are off the cards for this year, we are making it an all Australian affair and focussing on amazing local talent both emerging and established. We will also continue to push for gender balance, equality more broadly and continuing to emphasise the importance of community & human connection.
              </p>
              <p>
                The Australian events are also renowned for their culinary offerings and 2022 will be no exception. We are working closely with our venue partners to ensure we deliver an experience that is extra special in this regard.
              </p>
            </div>
          </div>
          <div className={'column is-one-fifths-desktop is-hidden-mobile is-vbottom'}>
            <Img fluid={maiImg} className={'has-image-centered'} style={{ maxWidth: '260px' }}/>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default About
