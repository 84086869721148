import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


const Team = ({ className }) => {
  const { liz, shruti, mai, adi, olga, duncan, yariv, yaniv, ben } = useStaticQuery(
    graphql`
      query {
              liz: file(relativePath: { eq: "organisers/liz.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
                shruti: file(relativePath: { eq: "organisers/shruti.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
                mai: file(relativePath: { eq: "organisers/mai.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        adi: file(relativePath: { eq: "organisers/adi.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         olga: file(relativePath: { eq: "organisers/olga.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        duncan: file(relativePath: { eq: "organisers/duncan.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        yariv: file(relativePath: { eq: "organisers/yariv.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        yaniv: file(relativePath: { eq: "organisers/yaniv.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ben: file(relativePath: { eq: "organisers/ben.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const duncanImg = duncan.childImageSharp.fluid
  const yarivImg = yariv.childImageSharp.fluid
  const yanivImg = yaniv.childImageSharp.fluid
  const lizImg = liz.childImageSharp.fluid
  const shrutiImg = shruti.childImageSharp.fluid
  const maiImg = mai.childImageSharp.fluid
  const adiImg = adi.childImageSharp.fluid
  const olgaImg = olga.childImageSharp.fluid
  const benImg = ben.childImageSharp.fluid

  return (
    <div id={'TEAM'} className={className + ' has-padding-30'}>
      <h4 className={'title is-2'}>OUR TEAM</h4>
      <div className={'columns is-flex is-size-7-mobile'}>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/douglassliz'}>
              <Img fluid={lizImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Liz</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/shruti-kembhavi'}>
              <Img fluid={shrutiImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Shruti</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/mai-isthebest'}>
              <Img fluid={maiImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Mai</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/olga-goloshchapova-0b310a33/'}>
              <Img fluid={olgaImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Olga</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/adi-polak-68548365'}>
              <Img fluid={adiImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Adi</p>
            </a>
          </div>
        </div>
      </div>
      <div className="columns is-flex is-size-7-mobile has-image-centered" style={{ width: '80%' }}>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/yanivrodenski'}>
              <Img fluid={yanivImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Yaniv</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/ytriffon'}>
              <Img fluid={yarivImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Yariv</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/duncanwbanks'}>
              <Img fluid={duncanImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Duncan</p>
            </a>
          </div>
        </div>
        <div className={'column'}>
          <div>
            <a href={'https://www.linkedin.com/in/benshi'}>
              <Img fluid={benImg} className={'has-image-centered'} style={{
                maxWidth: '160px',
              }}/>
              <span className="icon has-text-primary-dark social-linkedin">
                <i className="fab fa-linkedin fa-2x"/>
              </span>
              <p>Ben</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
