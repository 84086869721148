import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'


const Tracks = ({ className }) => {
  const { trackBg, automate, techTour, machine, web, coding } = useStaticQuery(
    graphql`
      query {
        trackBg: file(relativePath: { eq: "day-1-stp-7566.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        automate: file(relativePath: { eq: "cloud-containers-infrastructure.png" }) {
  childImageSharp {
    fluid(quality: 90, maxWidth: 600) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
        techTour: file(relativePath: { eq: "real-world-architecture.png" }) {
  childImageSharp {
    fluid(quality: 90, maxWidth: 600) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
        machine: file(relativePath: { eq: "data-machine-learning.png" }) {
  childImageSharp {
    fluid(quality: 90, maxWidth: 600) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
        web: file(relativePath: { eq: "modern-web.png" }) {
  childImageSharp {
    fluid(quality: 90, maxWidth: 600) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
        coding: file(relativePath: { eq: "programming-languages.png" }) {
  childImageSharp {
    fluid(quality: 90, maxWidth: 600) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
      }
    `,
  )

  const aside = trackBg.childImageSharp.fluid
  const automateIc = automate.childImageSharp.fluid
  const techTourIc = techTour.childImageSharp.fluid
  const machineIc = machine.childImageSharp.fluid
  const webIc = web.childImageSharp.fluid
  const codingIc = coding.childImageSharp.fluid

  return (
    <div className={className}>
      <div className={'columns'}>
        <div className={'column is-4 is-hidden-mobile'} style={{ padding: 0 }}>
          <div style={{ height: '100%' }}>
            <Img fluid={aside} className={'is-vcentered'}
                 style={{
                   objectFit: 'contain',
                   marginTop: '10%',
                   height: '80%',
                 }}/>
          </div>
        </div>
        <div
          className={'column is-8 has-text-centered has-background-white has-padding-top-50 has-padding-bottom-50 has-padding-30-mobile'}
          style={{
            borderRadius: '10px',
          }}>
          <h3 className={'title is-2'}>TRACKS</h3>
          <div className={'columns is-flex'}>
            <div className={'column'}>
              <Img fluid={automateIc} className={'has-image-centered'} style={{ maxWidth: '128px' }}/>
              <strong>Automate All the Things</strong>
              <p className="is-size-7-mobile">Cloud, Containers & Beyond Docker, Kubernetes, Vagrant, Cloud, PaaS
                etc.</p>
            </div>
            <div className={'column'}>
              <Img fluid={techTourIc} className={'has-image-centered'} style={{ maxWidth: '128px' }}/>
              <strong>Tech Tour of Companies</strong>
              <p className="is-size-7-mobile">Architecture, war stories and anything worth sharing</p>
            </div>
            <div className={'column'}>
              <Img fluid={machineIc} className={'has-image-centered'} style={{ maxWidth: '128px' }}/>
              <strong>Rise of the Machines</strong>
              <p className="is-size-7-mobile">Data, ML, and AI</p>
            </div>
          </div>
          <div className={'columns is-flex'}>
            <div className={'column is-offset-2-desktop is-4-desktop'}>
              <Img fluid={webIc} className={'has-image-centered'} style={{ maxWidth: '128px' }}/>
              <strong>Modern Web Development</strong>
              <p className="is-size-7-mobile">HTTP/2, Web frameworks, HTML5, CSS4, Javascript, libraries & tools to
                build web apps</p>
            </div>
            <div className={'column is-4-desktop'}>
              <Img fluid={codingIc} className={'has-image-centered'} style={{ maxWidth: '128px' }}/>
              <strong>Coding - Programming Languages</strong>
              <p className="is-size-7-mobile">Programming languages and software craftsmanship</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tracks
